const localeDefault = 'Ru-ru';
const optionsDefault = {
	minimumFractionDigits: 0,
};
let rub = new Intl.NumberFormat(localeDefault, optionsDefault);

export function createMaskAmount(value, locale = 'Ru-ru', options = {}) {
	const loc = locale !== localeDefault ? locale : null;
	const opt = Object.keys(options).length > 0 ? options : null;
	if (loc || opt) {
		rub = new Intl.NumberFormat(loc ?? localeDefault, opt ?? optionsDefault);
	}

	if (value) {
		return rub.format(value);
	}
	return '';
}

export function amountToNumber(value) {
	if (value) {
		return parseFloat(value.replace(/\s/g, ''));
	}
	return 0;
}
