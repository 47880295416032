import Auth from './auth'

import './middleware'

// Active schemes
import scheme_581d66e9 from './schemes/CustomScheme.js'
import scheme_3db80008 from './schemes/CustomCodeScheme.js'
import scheme_a5b13232 from './schemes/CustomScenarioSchemePhone.js'
import scheme_26d281f9 from './schemes/CustomScenarioSchemeEmail.js'

export default function (ctx, inject) {
  // Options
  const options = {"resetOnError":false,"scopeKey":"scope","rewriteRedirects":true,"fullPathRedirect":false,"watchLoggedIn":true,"redirect":{"login":"/?login=true","logout":"/","home":"/","callback":"/login"},"vuex":{"namespace":"auth"},"cookie":{"prefix":"auth.","options":{"path":"/"}},"localStorage":{"prefix":"auth."},"token":{"prefix":"_token."},"refresh_token":{"prefix":"_refresh_token."},"defaultStrategy":"local"}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // local
  $auth.registerStrategy('local', new scheme_581d66e9($auth, {"endpoints":{"login":{"url":"/api/auth/login","method":"post","propertyName":"token"},"logout":{"url":"/api/auth/logout","method":"post"},"user":{"url":"/api/auth/users/self","method":"get","propertyName":"data"}},"_name":"local"}))

  // code
  $auth.registerStrategy('code', new scheme_3db80008($auth, {"endpoints":{"login":{"url":"/api/auth/code/login","method":"post"},"logout":{"url":"/api/auth/logout","method":"post"},"user":{"url":"/api/auth/users/self","method":"get","propertyName":"data"}},"_name":"code"}))

  // scenario
  $auth.registerStrategy('scenario', new scheme_a5b13232($auth, {"endpoints":{"login":{"url":"/api/auth/phone-register/login","method":"post"},"logout":{"url":"/api/auth/logout","method":"post"},"user":{"url":"/api/auth/users/self","method":"get","propertyName":"data"}},"_name":"scenario"}))

  // scenario_register_phone
  $auth.registerStrategy('scenario_register_phone', new scheme_a5b13232($auth, {"endpoints":{"login":{"url":"/api/auth/phone-register/register","method":"post"},"logout":{"url":"/api/auth/logout","method":"post"},"user":{"url":"/api/auth/users/self","method":"get","propertyName":"data"}},"_name":"scenario_register_phone"}))

  // scenario_register_email
  $auth.registerStrategy('scenario_register_email', new scheme_26d281f9($auth, {"endpoints":{"login":{"url":"/api/auth/email-register/register","method":"post"},"logout":{"url":"/api/auth/logout","method":"post"},"user":{"url":"/api/auth/users/self","method":"get","propertyName":"data"}},"_name":"scenario_register_email"}))

  // sso
  $auth.registerStrategy('sso', new scheme_581d66e9($auth, {"endpoints":{"user":{"url":"/sso/user","method":"get","propertyName":"data"}},"tokenRequired":false,"tokenType":false,"_name":"sso"}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      console.error('[ERROR] [AUTH]', error)
    }
  })
}
